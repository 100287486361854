<template>
  <div class="grid grid-cols-12 gap-4" style="height: calc(100% - 110px);">
    <div class="md:col-span-12 sm:col-span-12 col-span-12 mt-6 box text-green">
      <div class="flex justify-end">
        <h1 class="f-30 p-6 mr-auto" style="line-height: 1;">MAPA DE <b>PROCESOS</b></h1>
        <!-- <Button label="crearFormatos" icon="pi pi-align-justify" class="p-button-text mr-5 ml-auto my-auto"
                @click="crearFormatos()"/> -->
        <Button
          v-if="$can('pharmasan.gestion.calidad.mapa-procesos.solicitudes') || $can('pharmasan.gestion.calidad.mapa-procesos.administrador')"
          label="Solicitudes" icon="pi pi-align-justify" class="p-button-text mr-5 ml-auto my-auto"
          @click="solicitudes()"/>
        <Button v-if="$can('pharmasan.gestion.calidad.mapa-procesos.administrador')" label="Administrar"
                icon="pi pi-align-justify" class="p-button-text mr-5 my-auto" @click="administrar()"/>
      </div>
      <!-- tipos procesos -->
      <div v-for="(item, key) in items" :key="item.id" class="flex flex-col items-center py-2 justify-center">
        <p v-if="item.hijos.length" class="my-2 text-blue-700 font-bold uppercase text-lg">{{ item.nombre }}</p>
        <!-- caja proceso -->
        <div v-if="item.hijos.length" class="py-3 w-full justify-center flex flex-wrap">
          <img v-show="key === 1 && item.posicion == 2" class="my-auto mr-3" src="/images/arrow-fat-right.png" alt=""
               style="height:70px">
          <div class="bg-gray-100 px-4 py-2 rounded-2xl flex flex-wrap">
            <div
              v-for="hijo in item.hijos" :key="hijo.id"
              class="caja-zoom font-medium bg-white border-2 border-gray-300 shadow-lg p-4 px-5 pt-7 rounded-2xl text-center m-3 mt-6 relative"
              style="width: 265px;"
              @click="verCarpetas(hijo.id)"
            >
              <div class="bg-blue-700 w-11 h-11 rounded-full absolute mx-auto pos-icon flex justify-center">
                <FileTextIcon class="text-theme-22 my-auto"/>
              </div>
              <p class="my-2 text-gray-600 uppercase">{{ hijo.nombre }}</p>
            </div>
          </div>
          <img v-show="key === 1 && item.posicion == 2" class="my-auto ml-3" src="/images/arrow-fat-right.png" alt=""
               style="height:70px">
        </div>
        <img v-show="key === 0 && items.length > 1" class="mt-4" src="/images/arrow-fat-down.png" alt=""
             style="height:70px">
      </div>
      <div class="flex flex-col sm:flex-row items-center py-2 justify-center">
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { onMounted, ref, watch } from 'vue'
import GetServices from './services/get.service'

export default {
  name: 'CalidadMapaPage',
  components: {},
  setup () {
    // const route = useRoute()
    // const storeTiposProcesoList = computed(() => store.getters.getTipoProcesos)
    const router = useRouter()
    const elPanel = ref({})
    const verModal = ref(false)
    const items = ref([])
    const tiposProceso = ref([])
    const _Get = ref(new GetServices())

    const administrar = () => {
      router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.administrar' })
    }

    const solicitudes = () => {
      router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.solicitudes' })
    }

    const crearFormatos = () => {
      router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.crearFormato' })
    }

    const verCarpetas = (id) => {
      router.push({
        name: 'pharmasan.gestion.calidad.mapa-procesos.ver', params: { id }
      })
    }

    const getMapaProcesos = () => {
      items.value = []
      // var order = items.value
      _Get.value.getMapa().then(({ data }) => {
        // console.log('getMapaProcesos------', data)
        items.value = data
      }).catch(err => {
        console.error(err.message)
      })
    }

    const datosMenu = () => {
      var aAlertMe = window.document.getElementsByClassName('p-panelmenu-header-link')
      // var aDataValues = []
      for (const item of aAlertMe) {
        // aDataValues.push(item)
        console.log('item-----', item)
      }
    }

    onMounted(() => {
      getMapaProcesos()
    })
    watch(tiposProceso, () => {
      // console.log('items watch---', items.value)
      // items.value = tiposProceso.value
    })
    return {
      administrar,
      solicitudes,
      crearFormatos,
      verCarpetas,
      datosMenu,
      tiposProceso,
      router,
      verModal,
      items,
      elPanel
    }
  }
}
</script>
<style lang="scss">
::v-deep .p-panelmenu-header {
  background-color: red;
}

.color-doc-word {
  color: #0080F9 !important;
}

.caja-zoom {
  transition: transform .2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.pos-icon {
  top: -29px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.pos-arrow {
  right: 5px;
  top: 50%;
  transform: translate(0%, -50%);
}

// colores-----------------
.text-green {
  color: #038080;
}

.br-green {
  border: 1px solid #038080;
}

.bg-grad {
  background: transparent linear-gradient(1deg, #0080F9 0%, #00DCB1 100%) 0% 0% no-repeat padding-box;
}

.bg-grad2 {
  background: transparent linear-gradient(1deg, #00DCB1 0%, #0080F9 100%) 0% 0% no-repeat padding-box;
}

.pi-file {
  color: rgb(180, 180, 180) !important;
}

.pi-file-excel, .pi-image {
  color: rgb(0, 187, 0) !important;
}

.pi-file-pdf {
  color: red !important;
}

.pi-youtube {
  color: rgb(3, 132, 218) !important;
}

.f-30 {
  font-size: 30px;
}

.f-24 {
  font-size: 24px;
}

.w-300 {
  width: 300px !important;
}

.wh-130 {
  width: 130px;
  height: 130px;
}

.w-240 {
  width: 240px;
}

.w-130 {
  width: 130px;
}

.h-130 {
  height: 130px;
}

.w-145 {
  width: 145px;
}

.h-145 {
  height: 145px;
}

.bg-cut {
  position: absolute;
  width: 65px;
  top: 0;
  left: 0;
}

.bg-cut2 {
  position: absolute;
  width: 65px;
  top: 0;
  right: 0;
}

.wh-50 {
  height: 50px;
  width: 50px;
}

.w-50 {
  width: 50px;
}

.br-50 {
  border-radius: 50px;
}

@media (max-width: 800px) {
  .wh-50 {
    height: 30px;
    width: 30px;
  }
  .w-50 {
    width: 30px;
  }
  .wh-130 {
    width: 90px;
    height: 90px;

    .p-5 {
      padding: 0.6rem;
    }
  }
  .h-130 {
    height: 90px;
  }
  .bg-cut {
    width: 45px;
  }
}

::v-deep(.p-organizationchart) {
  .p-person {
    padding: 0;
    border: 0 none;
  }

  .node-header, .node-content {
    padding: .5em .7rem;
  }

  .node-header {
    background-color: #495ebb;
    color: #ffffff;
  }

  .node-content {
    text-align: center;
    border: 1px solid #495ebb;
  }

  .node-content img {
    border-radius: 50%;
  }

  .department-cfo {
    background-color: #7247bc;
    color: #ffffff;
  }

  .department-coo {
    background-color: #a534b6;
    color: #ffffff;
  }

  .department-cto {
    background-color: #e9286f;
    color: #ffffff;
  }
}
</style>
